import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="container-xs">
              <Image
                src={require("./../../assets/images/aaronlionheart_circle.png")}
                alt="Aaron Lionheart"
                width={256}
                height={256}
                className="reveal-from-bottom"
                data-reveal-delay="300"
                style={{ padding: "20px", margin: "10px auto" }}
              />
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                <span className="text-color-primary">Aaron Lionheart</span>
              </h1>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Aaron Lionheart is a multi-talented and versatile individual who
                has made a name for himself in the world of video game
                development. With a background in software engineering and a
                passion for the arts, Aaron has become a sought-after developer,
                artist, composer, screenwriter, story writer, voice actor, and
                software engineer.
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Throughout his career, Aaron has shown a remarkable ability to
                bring his projects to life, from concept to finished product.
                His work as a video game developer has been praised for its
                innovative gameplay mechanics, stunning graphics, and
                captivating stories. As an artist, he brings a unique vision to
                his designs, which are often described as whimsical and
                imaginative. His musical compositions are also known for their
                emotional depth and ability to enhance the overall player
                experience.
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                In addition to his work as a video game developer, Aaron has
                also made a name for himself as a screenwriter and story writer.
                He has a talent for crafting compelling narratives that immerse
                players in rich and complex worlds. And as a voice actor, he
                brings his characters to life with dynamic performances that are
                both engaging and memorable.
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                With his unique blend of technical expertise and artistic
                vision, Aaron Lionheart has become one of the most sought-after
                names in the world of video game development. He continues to
                push the boundaries of what is possible in the industry, and his
                work continues to inspire and captivate audiences around the
                world.
              </p>
              <br/>
              <div className="reveal-from-bottom" data-reveal-delay="400">
                <ButtonGroup>
                  <Link to="./business">
                    <Button color="primary" wideMobile>
                      Business Contact
                    </Button>
                  </Link>
                  <Link to="./resume">
                    <Button color="dark" wideMobile>
                      Resume / Experience
                    </Button>
                  </Link>
                </ButtonGroup>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
