import React from 'react';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from '../Checkout';
import Input from "../components/elements/Input";
import { useState } from "react";
import classNames from "classnames";

const sectionHeader = {
  title: 'Donate',
  paragraph: 'All donations are appreciated, and proceeds go towards furthering the mission outlined on the values page.'
};

const initialOptions = {
  "client-id": "AZVbBbG7tk8aLmO0iS1RgqCiZHARVGXtcUvl3JMpYJd6e_E97kd1Gx-7t1jvNSZunb15NFPYAK6WH0Qy",
  currency: "USD",
  intent: "capture",
};

const innerClasses = classNames(
  "section-inner",
  "reveal-from-bottom",
);

const Donate = () => {
  const [messageError, setMessageError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [resultMessage, setResultMessage] = useState("Your donation has been received, thank you.");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("5.00");
  const [formState, setFormState] = useState(0);

  if(!window.amount){
    window.amount = email;
  }

  if(!window.message){
    window.message = message;
  }
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    window.message = event.target.value;
  };

  const handleEmailChange = (event) => {
    const number = Number(event.target.value).toFixed(2) ;
    setEmail(number);
    window.amount = number;
  };

  const validateEmail = (mail) => {
    if(isNaN(mail)){
      return false;
    }
    if(mail < 5.00 || mail > 5000.00){
      return false;
    }
    return true;
  };


  const OnCreatePaypalOrder = (data, actions, donationMessage, donationAmount) => {
    let errors = false;
    if (donationMessage.length < 10) {
      setMessageError("Please make your donation message at least 10 characters long.");      
      errors = true;
    }else{
      setMessageError("");  
    }

    if (!validateEmail(donationAmount)) {
      setEmailError("Please enter a valid number between $5 and $5000.");
      errors = true;
    }
    else{
      setEmailError("");  
    }

    if(errors){
      return
    }

    return fetch("https://api.heroesraid.com/execute/create-paypal-donation", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ Message: donationMessage, DonationAmount: Number(donationAmount).toFixed(2) }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        var result = data.result;
        if (result !== 5) {
          setResultMessage("An error occured with PayPal, sorry for the inconvenience.");
          setFormState(1);
          return null;
        } else {
          window.token = data.tokenOrMessage;
          return window.token;
        }
      });
  }

  const OnApprovePaypalOrder = (data, actions, donationMessage, donationAmount) => {
    var payload = {
      PayerId: data.payerID,
      OrderId: window.token,
      Message: donationMessage,
      DonationAmount:  Number(donationAmount).toFixed(2),
    };

    return fetch("https://api.heroesraid.com/execute/finalize-paypal-donation", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        var result = data.result;
        if (result !== 5) {
          setResultMessage("An error occured while processing your donation.");
          setFormState(1);
        } else {
          setResultMessage("Your donation has been received, thank you.");
          setFormState(1);
        }
        return data;
      });
  }

  const formState1 = <><div className="cta-action">
  <p style={{ fontSize: "12pt", margin: "0px",  color: "white" }}>
    Enter your donation message here.
  </p>
  <Input
    type="textarea"
    id="message"
    label="Message"
    labelHidden
    hasIcon="right"
    placeholder="Message"
    value={message}
    onChange={handleMessageChange}
  />
  <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
    {messageError}
  </p>
</div>
<div className="cta-action" style={{ margin: "20px 0px" }}>
  <p style={{ fontSize: "12pt", margin: "0px",  color: "white" }}>
    Enter the amount you would like to donate in USD between $5 and $5000
  </p>
  <Input
    id="email"
    type="number"
    label="donation"
    labelHidden
    hasIcon="right"
    placeholder="USD Donation $"
    value={email}
    onChange={handleEmailChange}
  >
  </Input>
  <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
    {emailError}
  </p>
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      margin: "20px 0px",            
    }}
  ><PayPalScriptProvider options={initialOptions} >
                          <Checkout createOrder={OnCreatePaypalOrder} approveOrder={OnApprovePaypalOrder} message={message} amount={email}/>
                        </PayPalScriptProvider></div></div></>;

  const formState2 =   <div
  style={{
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",            
  }}><div style={{height: "400px"}}><p>{resultMessage}</p></div></div>;

  return (
    <>
      <GenericSection>
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className={innerClasses}>

      {formState === 0 ? formState1 : formState2}

      </div>

      </GenericSection>
    </>
  );
}

export default Donate;