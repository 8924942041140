import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Twitch from '../components/elements/Twitch';
import {SocialIcon} from "react-social-icons";
import ReactTooltip from "react-tooltip"

const sectionHeader = {
  title: 'Social',
  paragraph: 'Social Links and Twitch Stream.'
};

// const bitChute = {
//   icon:
//     'M 44.933594 32.933594 C 43.601562 31.867188 41.332031 30.933594 38 30.132812 C 41.066406 29.332031 43.199219 28.398438 44.398438 27.066406 C 45.601562 25.734375 46.132812 24.265625 46.132812 22.667969 C 46.132812 21.066406 45.601562 19.601562 44.667969 18.265625 C 43.734375 16.933594 42.398438 15.867188 40.800781 15.066406 C 39.199219 14.398438 36.398438 14 32.398438 14 L 15.867188 14 L 15.867188 14.933594 C 17.464844 14.933594 18.535156 15.066406 19.066406 15.332031 C 19.601562 15.601562 20 16 20.265625 16.535156 C 20.535156 17.066406 20.667969 18.132812 20.667969 20 L 20.667969 42.265625 C 20.667969 44.132812 20.535156 45.332031 20.265625 45.734375 C 20 46.265625 19.601562 46.535156 19.066406 46.933594 C 18.535156 47.199219 17.464844 47.332031 15.867188 47.332031 L 15.867188 48.265625 L 33.332031 48.265625 C 38.132812 48.265625 41.867188 47.199219 44.398438 45.199219 C 46.535156 43.464844 47.464844 41.332031 47.464844 38.800781 C 47.867188 36.398438 46.800781 34.398438 44.933594 32.933594 Z M 28.933594 15.867188 C 31.464844 15.867188 33.199219 16.132812 34.265625 16.667969 C 35.332031 17.199219 36.265625 17.867188 36.800781 18.933594 C 37.332031 19.867188 37.734375 21.199219 37.734375 22.667969 C 37.734375 24.132812 37.464844 25.464844 36.800781 26.398438 C 36.132812 27.332031 35.332031 28.132812 34.132812 28.667969 C 32.933594 29.199219 31.199219 29.464844 28.800781 29.464844 Z M 38 42.933594 C 37.332031 44.132812 36.535156 44.933594 35.332031 45.601562 C 34.132812 46.132812 32.933594 46.535156 31.601562 46.535156 C 30.667969 46.535156 30 46.265625 29.601562 45.867188 C 29.199219 45.464844 28.933594 44.667969 28.933594 43.734375 L 28.933594 31.332031 C 31.464844 31.332031 33.464844 31.601562 34.800781 32.132812 C 36.132812 32.667969 37.066406 33.464844 37.867188 34.800781 C 38.667969 36.132812 38.933594 37.464844 38.933594 39.066406 C 38.933594 40.535156 38.535156 41.867188 38 42.933594 Z M 38 42.933594',
//   mask:
//     'M0,0v64h64V0H0z M 44.933594 32.933594 C 43.601562 31.867188 41.332031 30.933594 38 30.132812 C 41.066406 29.332031 43.199219 28.398438 44.398438 27.066406 C 45.601562 25.734375 46.132812 24.265625 46.132812 22.667969 C 46.132812 21.066406 45.601562 19.601562 44.667969 18.265625 C 43.734375 16.933594 42.398438 15.867188 40.800781 15.066406 C 39.199219 14.398438 36.398438 14 32.398438 14 L 15.867188 14 L 15.867188 14.933594 C 17.464844 14.933594 18.535156 15.066406 19.066406 15.332031 C 19.601562 15.601562 20 16 20.265625 16.535156 C 20.535156 17.066406 20.667969 18.132812 20.667969 20 L 20.667969 42.265625 C 20.667969 44.132812 20.535156 45.332031 20.265625 45.734375 C 20 46.265625 19.601562 46.535156 19.066406 46.933594 C 18.535156 47.199219 17.464844 47.332031 15.867188 47.332031 L 15.867188 48.265625 L 33.332031 48.265625 C 38.132812 48.265625 41.867188 47.199219 44.398438 45.199219 C 46.535156 43.464844 47.464844 41.332031 47.464844 38.800781 C 47.867188 36.398438 46.800781 34.398438 44.933594 32.933594 Z M 28.933594 15.867188 C 31.464844 15.867188 33.199219 16.132812 34.265625 16.667969 C 35.332031 17.199219 36.265625 17.867188 36.800781 18.933594 C 37.332031 19.867188 37.734375 21.199219 37.734375 22.667969 C 37.734375 24.132812 37.464844 25.464844 36.800781 26.398438 C 36.132812 27.332031 35.332031 28.132812 34.132812 28.667969 C 32.933594 29.199219 31.199219 29.464844 28.800781 29.464844 Z M 38 42.933594 C 37.332031 44.132812 36.535156 44.933594 35.332031 45.601562 C 34.132812 46.132812 32.933594 46.535156 31.601562 46.535156 C 30.667969 46.535156 30 46.265625 29.601562 45.867188 C 29.199219 45.464844 28.933594 44.667969 28.933594 43.734375 L 28.933594 31.332031 C 31.464844 31.332031 33.464844 31.601562 34.800781 32.132812 C 36.132812 32.667969 37.066406 33.464844 37.867188 34.800781 C 38.667969 36.132812 38.933594 37.464844 38.933594 39.066406 C 38.933594 40.535156 38.535156 41.867188 38 42.933594 Z M 38 42.933594',
//   color: '#D20134'
// };

// const dailymotion = {
//   icon:
//     'M 33.199219 14.398438 L 17.800781 14.398438 C 17.398438 14.398438 17 14.800781 17 15.199219 L 17 50.898438 C 17 51.300781 17.398438 51.699219 17.800781 51.699219 L 33.199219 51.699219 C 43.800781 51.699219 51 44.199219 51 33.101562 C 51 21.898438 43.800781 14.398438 33.199219 14.398438 Z M 32.699219 42.898438 C 32.601562 42.898438 32.5 42.898438 32.398438 42.800781 C 32.398438 42.800781 32.398438 42.800781 32.300781 42.800781 C 32.300781 42.800781 32.300781 42.800781 32.199219 42.800781 L 27 42.800781 C 26.601562 42.800781 26.199219 42.5 26.199219 42 L 26.199219 23.699219 C 26.199219 23.300781 26.5 22.898438 27 22.898438 L 32.199219 22.898438 C 32.199219 22.898438 32.199219 22.898438 32.300781 22.898438 C 32.300781 22.898438 32.300781 22.898438 32.398438 22.898438 C 32.5 22.898438 32.601562 22.800781 32.699219 22.800781 C 32.898438 22.800781 33 22.800781 33.199219 22.800781 C 33.5 22.800781 33.699219 22.800781 34 22.898438 C 38.601562 23.5 41.699219 27.300781 41.699219 32.699219 C 41.699219 38.101562 38.699219 42 34 42.5 C 33.699219 42.5 33.5 42.601562 33.199219 42.601562 C 33 42.898438 32.898438 42.898438 32.699219 42.898438 Z M 32.699219 42.898438',
//   mask:
//     'M0,0v64h64V0H0z M 33.199219 14.398438 L 17.800781 14.398438 C 17.398438 14.398438 17 14.800781 17 15.199219 L 17 50.898438 C 17 51.300781 17.398438 51.699219 17.800781 51.699219 L 33.199219 51.699219 C 43.800781 51.699219 51 44.199219 51 33.101562 C 51 21.898438 43.800781 14.398438 33.199219 14.398438 Z M 32.699219 42.898438 C 32.601562 42.898438 32.5 42.898438 32.398438 42.800781 C 32.398438 42.800781 32.398438 42.800781 32.300781 42.800781 C 32.300781 42.800781 32.300781 42.800781 32.199219 42.800781 L 27 42.800781 C 26.601562 42.800781 26.199219 42.5 26.199219 42 L 26.199219 23.699219 C 26.199219 23.300781 26.5 22.898438 27 22.898438 L 32.199219 22.898438 C 32.199219 22.898438 32.199219 22.898438 32.300781 22.898438 C 32.300781 22.898438 32.300781 22.898438 32.398438 22.898438 C 32.5 22.898438 32.601562 22.800781 32.699219 22.800781 C 32.898438 22.800781 33 22.800781 33.199219 22.800781 C 33.5 22.800781 33.699219 22.800781 34 22.898438 C 38.601562 23.5 41.699219 27.300781 41.699219 32.699219 C 41.699219 38.101562 38.699219 42 34 42.5 C 33.699219 42.5 33.5 42.601562 33.199219 42.601562 C 33 42.898438 32.898438 42.898438 32.699219 42.898438 Z M 32.699219 42.898438',
//   color: '#00C6F6'
// };

const rumble = {
  icon:
    'M 47.800781 48.300781 L 39.300781 37 C 43.699219 35.199219 46.800781 30.898438 46.800781 25.898438 C 46.800781 19.300781 41.398438 13.898438 34.699219 13.898438 L 21.898438 13.898438 C 21.800781 13.898438 21.800781 13.898438 21.800781 13.898438 L 19.601562 13.898438 C 19.199219 13.898438 18.800781 14.199219 18.800781 14.699219 L 18.800781 48.699219 C 18.800781 49.101562 19.101562 49.5 19.601562 49.5 L 27 49.5 C 27.398438 49.5 27.800781 49.199219 27.800781 48.699219 L 27.800781 37.898438 L 30.699219 37.898438 L 39.199219 49.199219 C 39.398438 49.398438 39.601562 49.5 39.800781 49.5 L 47.199219 49.5 C 47.5 49.5 47.800781 49.300781 47.898438 49.101562 C 48 48.800781 47.898438 48.5 47.800781 48.300781 Z M 34.101562 30.898438 C 34 30.898438 33.898438 30.898438 33.699219 30.898438 L 27.800781 30.898438 C 27.800781 30.898438 27.699219 30.898438 27.699219 30.898438 C 27.699219 30.898438 27.601562 30.800781 27.601562 30.800781 L 27.601562 21.300781 C 27.601562 21.199219 27.601562 21.199219 27.699219 21.199219 C 27.699219 21.199219 27.699219 21.199219 27.800781 21.199219 C 27.800781 21.199219 27.800781 21.199219 27.898438 21.199219 L 33.800781 21.199219 C 33.898438 21.199219 34.101562 21.199219 34.199219 21.199219 C 36.601562 21.398438 38.5 23.5 38.5 26.101562 C 38.5 28.5 36.5 30.699219 34.101562 30.898438 Z M 34.101562 30.898438',
  mask:
    'M0,0v64h64V0H0zM 47.800781 48.300781 L 39.300781 37 C 43.699219 35.199219 46.800781 30.898438 46.800781 25.898438 C 46.800781 19.300781 41.398438 13.898438 34.699219 13.898438 L 21.898438 13.898438 C 21.800781 13.898438 21.800781 13.898438 21.800781 13.898438 L 19.601562 13.898438 C 19.199219 13.898438 18.800781 14.199219 18.800781 14.699219 L 18.800781 48.699219 C 18.800781 49.101562 19.101562 49.5 19.601562 49.5 L 27 49.5 C 27.398438 49.5 27.800781 49.199219 27.800781 48.699219 L 27.800781 37.898438 L 30.699219 37.898438 L 39.199219 49.199219 C 39.398438 49.398438 39.601562 49.5 39.800781 49.5 L 47.199219 49.5 C 47.5 49.5 47.800781 49.300781 47.898438 49.101562 C 48 48.800781 47.898438 48.5 47.800781 48.300781 Z M 34.101562 30.898438 C 34 30.898438 33.898438 30.898438 33.699219 30.898438 L 27.800781 30.898438 C 27.800781 30.898438 27.699219 30.898438 27.699219 30.898438 C 27.699219 30.898438 27.601562 30.800781 27.601562 30.800781 L 27.601562 21.300781 C 27.601562 21.199219 27.601562 21.199219 27.699219 21.199219 C 27.699219 21.199219 27.699219 21.199219 27.800781 21.199219 C 27.800781 21.199219 27.800781 21.199219 27.898438 21.199219 L 33.800781 21.199219 C 33.898438 21.199219 34.101562 21.199219 34.199219 21.199219 C 36.601562 21.398438 38.5 23.5 38.5 26.101562 C 38.5 28.5 36.5 30.699219 34.101562 30.898438 Z M 34.101562 30.898438',
  color: '#85C742'
};

const patreon = {
  icon:
    'M 21.5 16.101562 L 21.5 50.5 L 15.199219 50.5 L 15.199219 16.101562 Z M 38.101562 16.101562 C 45.199219 16.101562 51 21.898438 51 29 C 51 36.101562 45.199219 41.898438 38.101562 41.898438 C 31 41.898438 25.199219 36.101562 25.199219 29 C 25.199219 21.898438 31 16.101562 38.101562 16.101562 Z M 38.101562 16.101562 ',
  mask:
    'M0,0v64h64V0H0z M 21.5 16.101562 L 21.5 50.5 L 15.199219 50.5 L 15.199219 16.101562 Z M 38.101562 16.101562 C 45.199219 16.101562 51 21.898438 51 29 C 51 36.101562 45.199219 41.898438 38.101562 41.898438 C 31 41.898438 25.199219 36.101562 25.199219 29 C 25.199219 21.898438 31 16.101562 38.101562 16.101562 Z M 38.101562 16.101562 ',
  color: '#FF5900'
};


// const tiktokAlt = {
//   icon:
//     'M 39 14.199219 C 39.601562 19.300781 42.398438 22.300781 47.300781 22.601562 L 47.300781 28.300781 C 44.5 28.601562 42 27.601562 39.101562 25.898438 L 39.101562 36.601562 C 39.101562 50.101562 24.398438 54.398438 18.398438 44.699219 C 14.601562 38.5 16.898438 27.5 29.101562 27.101562 L 29.101562 33.101562 C 28.199219 33.199219 27.199219 33.5 26.300781 33.800781 C 23.601562 34.699219 22 36.398438 22.5 39.5 C 23.300781 45.300781 34 47 33.101562 35.699219 L 33.101562 14.398438 Z M 39 14.199219 ',
//   mask:
//     'M0,0v64h64V0H0z M 39 14.199219 C 39.601562 19.300781 42.398438 22.300781 47.300781 22.601562 L 47.300781 28.300781 C 44.5 28.601562 42 27.601562 39.101562 25.898438 L 39.101562 36.601562 C 39.101562 50.101562 24.398438 54.398438 18.398438 44.699219 C 14.601562 38.5 16.898438 27.5 29.101562 27.101562 L 29.101562 33.101562 C 28.199219 33.199219 27.199219 33.5 26.300781 33.800781 C 23.601562 34.699219 22 36.398438 22.5 39.5 C 23.300781 45.300781 34 47 33.101562 35.699219 L 33.101562 14.398438 Z M 39 14.199219 ',
//   color: '#C4003D'
// };



const Values = () => {

  return (
    <>
      <GenericSection>
        <SectionHeader data={sectionHeader} className="center-content" />        
        <Twitch />  
        <div data-reveal-delay="200"  style={{ display: 'flex', flexWrap: "wrap",  justifyContent: 'center', alignContent:'center', gap: '10px', marginTop: '32px'}} >  
        <div style={{display: 'flex',  justifyContent: 'center', alignContent:'center', gap: '10px' }} >
        <SocialIcon data-tip data-for="tooltip-youtube" url="https://youtube.com/@aaron.lionheart" label="YouTube"/>
        <ReactTooltip id="tooltip-youtube" place="top" type="dark" effect="solid" >
        YouTube
        </ReactTooltip>
        <SocialIcon data-tip data-for="tooltip-instagram" url="https://www.instagram.com/aaron.lionheart/" label="Instagram"/>
        <ReactTooltip id="tooltip-instagram" place="top" type="dark" effect="solid" >
        Instagram
        </ReactTooltip>
        <SocialIcon data-tip data-for="tooltip-twitch" url="https://www.twitch.tv/aaronlionheart" label="Twitch"/>
        <ReactTooltip id="tooltip-twitch" place="top" type="dark" effect="solid" >
        Twitch
        </ReactTooltip>
        </div>
        <div style={{display: 'flex',  justifyContent: 'center', alignContent:'center', gap: '10px' }} >
        <SocialIcon data-tip data-for="tooltip-rumble" url="https://rumble.com/c/aaronlionheart" defaultSVG={rumble} label="Rumble"/>
        <ReactTooltip id="tooltip-rumble" place="top" type="dark" effect="solid" >
        Rumble
        </ReactTooltip>
        <SocialIcon data-tip data-for="tooltip-twitter" url="https://twitter.com/AaronLionheart8" label="Twitter"/>
        <ReactTooltip id="tooltip-twitter" place="top" type="dark" effect="solid" >
        Twitter
        </ReactTooltip>
        <SocialIcon data-tip data-for="tooltip-patreon" url="https://www.patreon.com/aaronlionheart" defaultSVG={patreon} label="Patreon"/>
        <ReactTooltip id="tooltip-patreon" place="top" type="dark" effect="solid" >
        Patreon
        </ReactTooltip>
        <SocialIcon data-tip data-for="tooltip-tiktok" url="https://www.tiktok.com/@aaronlionheart" bgColor="#C4003D" label="TikTok"/>
        <ReactTooltip id="tooltip-tiktok" place="top" type="dark" effect="solid" >
        TikTok
        </ReactTooltip>
        </div>
        </div>
      </GenericSection>
    </>
  );
}

export default Values;