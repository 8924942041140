import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PropTypes from 'prop-types';
import classNames from "classnames";

const propTypes = {
    message: PropTypes.string,
    amount: PropTypes.string,    
    createOrder: PropTypes.func,
    approveOrder: PropTypes.func,
  }
  
const defaultProps = {
    message: "Default Donation Message",
    amount: "5.00",    
    createOrder: function() { 
        return false;
    },
    approveOrder: function() { 
        return false;
    },
}

const innerClasses = classNames(
    "container",
    "flexContainer"
  );
  
const Checkout = ({amount, createOrder, approveOrder, message, donationAmount, ...props}) => {
    const [{ isPending } ] = usePayPalScriptReducer();
    
    const createOrderHoist = (data, actions) => createOrder(data, actions, window.message, window.amount);
    const approveOrderHoist = (data, actions) => approveOrder(data, actions, window.message, window.amount);

    return (
        <>
            {isPending ? <>  <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "75px 0px",
        }}
      >
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="spinner path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div></> : (
                <>
                    <PayPalButtons 
                        style={{ layout: "vertical", "label":"donate"}}
                        className={innerClasses}
                        createOrder={createOrderHoist}
                        onApprove={approveOrderHoist}                      
                    />
                </>
            )}
        </>
    );
}

Checkout.propTypes = propTypes;
Checkout.defaultProps = defaultProps;

export default Checkout;