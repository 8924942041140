import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
      <li>
          <Link to="./resume">Resume</Link>
      </li>
       <li>
          <Link to="./values">Values</Link>
       </li>
       <li>
          <Link to="./donate">Donate</Link>
       </li>
       <li>
          <Link to="./social">Social</Link>
       </li>
       <li>
          <Link to="./business">Business Contact</Link>
       </li>
      </ul>
    </nav>
  );
}

export default FooterNav;