import React, {useState} from 'react';

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

 const Twitch = ({targetId, channel, w, height, autoplay, layout, ...props}) =>  {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 940;
  const targetWidth = (width < breakpoint) ? width : breakpoint;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    const script = document.createElement('script');
    script.setAttribute(
      'src',
      EMBED_URL
    );
    script.addEventListener('load', () => {   
      window.twitchEmbed = new window.Twitch.Embed(targetId, { channel, width: targetWidth, height, autoplay, layout });
    });
        document.body.appendChild(script);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(window.twitchEmbed){
      if(window.twitchEmbed._iframe.width !== targetWidth){
        window.twitchEmbed._iframe.width = targetWidth;
      }
  }
  return (
    <div style={{display: "flex", justifyContent: "center", alignContent: "center", minHeight: "488px"}}>
      <div id={targetId} style={{maxWidth: '940px'}}/>
    </div>
  );
}

Twitch.defaultProps = {
    targetId: 'twitch-embed',
    channel: 'aaronlionheart',
    w: '940',
    height: '480',
    autoplay: true,
    layout: 'video'
}

export default Twitch;