import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";
import { useState } from "react";
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const sectionHeader = {
  title: 'Contact',
  paragraph: 'Use this form to get in touch with me regarding any software engineering contracting roles or projects, alternatively use this form to express interest or enquire about any projects Lionheart Global Corporation is currently undertaking.'
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [messageError, setMessageError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fetchError, setFetchError] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState(0);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const outerClasses = classNames(
    "cta section center-content-mobile",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner",
    "reveal-from-bottom",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  const sendMessage = () => {
    setMessageError("");
    setEmailError("");
    setFetchError("");
    let errors = false;
    if (message.length < 50) {
      setMessageError("Please make your message at least 50 characters long.");
      errors = true;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      errors = true;
    }

    if (errors) {
      return;
    }

    setFormState(1);
    postData("https://api.heroesraid.com/execute/send/message", {
      email: email,
      message: message,
    }).then((success) => {
      console.log(success);
      if (success) {
        setFormState(2);
      } else {
        setFetchError(
          "There was an error communicating with the target server, try again later."
        );
        setFormState(0);
      }
    });
  };

  const formState1 = (
    <>
      <div className="cta-action">
        <p style={{ fontSize: "12pt", margin: "0px",  color: "white" }}>
          Enter your message here.
        </p>
        <Input
          type="textarea"
          id="message"
          label="Message"
          labelHidden
          hasIcon="right"
          placeholder="Message"
          value={message}
          onChange={handleMessageChange}
        />
        <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
          {messageError}
        </p>
      </div>
      <div className="cta-action" style={{ margin: "20px 0px" }}>
        <p style={{ fontSize: "12pt", margin: "0px",  color: "white" }}>
          Enter the email you would like the reply to be sent to.
        </p>
        <Input
          id="email"
          type="email"
          label="Email"
          labelHidden
          hasIcon="right"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
       />
        <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
          {emailError}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <Button
            className="button button-secondary"
            style={{ width: "100%" }}
            onClick={sendMessage}
          >
            Send Message
          </Button>
          <br />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <p style={{ fontSize: "9pt", margin: "0px", color: "#ffc107" }}>
            {fetchError}
          </p>
        </div>
      </div>
    </>
  );

  const formState2 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "161.5px 0px",
        }}
      >
        <svg className="spinner" viewBox="0 0 50 50">
          <circle
            className="spinner path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    </>
  );

  const formState3 = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "166px 0px",
        }}
      >
        <p style={{ fontSize: "12pt", margin: "0px",  color: "white" }}>Message received, thank you.</p>
      </div>
    </>
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container" style={{paddingBottom: "80px"}}>
        <div  className="generic-inner section-inner" style={{paddingBottom: "0px", paddingTop: "80px"}}>
          <div className="section-header center-content" style={{paddingBottom: "0px"}}>
          <SectionHeader data={sectionHeader} className="center-content" />
          </div>
          </div>
        <div className={innerClasses}>
         
          {formState === 0
            ? formState1
            : formState === 1
            ? formState2
            : formState3}
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
