import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Resume',
    paragraph: 'Senior experience roles followed by a skills matrix.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-left" data-reveal-delay="0">          
              <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Rio Tinto</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Worked on stabilizing and improving the rail system tracking, reporting, and location services on both the front-end and back-end.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      F#, Git, Unit Testing, SQL
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  July 2023 - November 2023
                  </span>
                </div>
              </div>
            </div>

          <div className="tiles-item reveal-from-top" data-reveal-delay="200">          
              <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">BHP</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Part of the PRISM Squad working on mining industry shipping related optimization solutions, implementing new features on both the front-end and back-end.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      JavaScript, Angular, HTML, CSS, Git, Unit Testing, SQL
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  Jan 2023 - May 2023
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="0">          
              <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Squiz</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Part of the Plug & Play Squad, Took over as lead developer
of the Squiz Workplace product, in charge of implementing
new features, bug fixes, client patches and releases.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      JavaScript, React, PHP, HTML, CSS, Git, Cypress, Unit Testing, SQL, CI/CD
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  Jan 2022 - Nov 2022
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="100">
            <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Bunnings</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Part of the MS Front End React Squad, creating new projects
from scratch with modern react, redux-sagas, typescript,
styled components etc. As well as updating old projects in
our domain, API side included. Performing stakeholder
meetings, showcases, engaging with business developers
etc. Main projects include “Self Checkout, and “Logistics
Management App”
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      JavaScript, React, Redux, Typescript, C#, Cypress, Unit Testing, SQL, CI/CD
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  Jan 2021 - Jan 2022
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
            <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Heroes Raid</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Working on releasing Heroes Raid. A 3D always online game, that constantly talks to the API server, supports millions of people, including realtime multiplayer. Designed to be free to play with modern monetization techniques to increase the user base. Combined with a long term "new content every week" model.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      C#, DotNet Core, Unity Engine, Unit Testing, SQL, Azure, AWS, CI/CD, Git
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  2021 - In Progress
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="100">          
              <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">SquareCode / Livemine</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Worked primarily on LiveMine, Clients use LiveMine for mine specific shift
management, big data and worker co-ordination. I worked very closely with the LiveMine team spending a few months in their Fremantle office.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      Xamarin – Android App, Vuetify, JavaScript, VueJS, TypeScript, Azure, SQL, Restful API back-end, Unit
Testing, Git
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  Jan 2019 - Dec 2021
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
            <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Super Friends Party</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Released an online multiplayer party game that can be played with up to 6 people at once.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      C#, DotNet Core, Unity Engine, Unit Testing, SQL, Azure, AWS, CI/CD, Git
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                 Jan 2018 - Jan 2019
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
            <div className="tiles-item-inner">
              <span className="testimonial-item-name text-color-high">Grand Battle</span>
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                  Released a single player rpg game.
                      </p>

                      <p className="text-sm mb-0" style={{marginTop: '20px', fontSize: '11px', lineHeight: '13px'}}>
                      C#, DotNet Core, Unity Engine, Unit Testing, CI/CD, Git
                        </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  Jan 2017 - Jan 2018
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;