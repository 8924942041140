import React from 'react';
// import sections
import ContactForm from '../components/sections/ContactForm';


const Business = () => {

  return (
    <>
      <ContactForm />
    </>
  );
}

export default Business;