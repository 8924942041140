import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import classNames from "classnames";

const sectionHeader = {
  title: 'Values',
  paragraph: 'A short summary of my personal beliefs, values and mission.'
};

const innerClasses = classNames( 
  "reveal-from-bottom");

const Values = () => {

  return (
    <>
      <GenericSection>
        <SectionHeader data={sectionHeader} className="center-content" />        
        <h4 className={innerClasses} data-reveal-delay="200">Core Statement</h4>
        <p className={innerClasses} data-reveal-delay="400">More than anything I believe that personal liberty and autonomy must be respected and maintained regarding all choices a person makes, as long as an individual is not harming others or the community they should be allowed to engage in any behavior that they choose.</p>
        <p className={innerClasses} data-reveal-delay="600">I believe in the value of technology to heal the world of all the damage our predecessors have caused, as well as using technology to repair our health and improve our longevity and happiness.</p>
        <p className={innerClasses} data-reveal-delay="800">My main life goals are to improve the world and people's lives through the use of various technological applications including artificial intelligence, robotics, nanotechnology, virtual entertainment, and applied sciences. Secondly, to create, lead and work in highly talented cooperative teams that make a tangible positive difference in the world and people's lives.</p>        
      </GenericSection>
    </>
  );
}

export default Values;